const paginationConstant = {
  PAGE_SIZE: 10,
};

const pageProperties = {
  WIDTH: 1024,
  OVERFLOW_Y: 250,
};

export const STORAGE_CONST = {
  USER_INFO: "TOKEN",
  FRONT_TOKEN: "FRONT_TOKEN"
};

export { paginationConstant, pageProperties };
