import { STORAGE_CONST } from "../constants/constant";
import LocalStorageService from "./local-storage.service";

const AuthService = {
  isTokenExist,
  getToken,
  getFrontToken,
  isFrontTokenExist,
  getUserInfo,
  getRole,
};

function getRole() {
  let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
    return userInfo.roles[0];
  }
  return null;
}

function isTokenExist() {
  let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
  if (userInfo) {
    return true;
  }
  return false;
}

function isFrontTokenExist() {
  let userFrontInfo = LocalStorageService.get(STORAGE_CONST.FRONT_TOKEN);
  if (userFrontInfo) {
    return true;
  }
  return false;
}

function getToken() {
  let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
    return userInfo.token;
  }
  return null;
}

function getFrontToken() {
  let userInfoFront = LocalStorageService.get(STORAGE_CONST.FRONT_TOKEN);
  if (userInfoFront) {
    userInfoFront = JSON.parse(userInfoFront);
    return userInfoFront.token;
  }
  return null;
}

function getUserInfo() {
  let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
  if (userInfo) {
    return JSON.parse(userInfo);
  }
  return null;
}

export default AuthService;
