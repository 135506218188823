import React from "react";
import { useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import AuthService from "../util/auth.service";
import AppLocale from "../languageProvider";

const AuthenticatedAppRoutes = React.lazy(() =>
  import("../routes/authenticated-app-routes")
);
const UnAuthenticatedAppRoutes = React.lazy(() =>
  import("../routes/unauthenticated-app-routes")
);

const Routes = () => {
  const locale = useSelector(({ settings }) => settings.locale);

  console.log(locale,"locale");

  const isAdminTokenExist = AuthService.isTokenExist();
  // const isFrontTokenExist = AuthService.isFrontTokenExist();
  const currentAppLocale = AppLocale.find((x) => x.id === locale);

  return (
    <ConfigProvider locale={currentAppLocale.lang.antdLocale}>
      {isAdminTokenExist ? <AuthenticatedAppRoutes /> : <UnAuthenticatedAppRoutes />}
    </ConfigProvider>
  );
};

export default Routes;
