import { createSlice } from "@reduxjs/toolkit";

const ReportSlice = createSlice({
  name: "Report",
  initialState: {
    loading: false,
    error:"",
    data:null,
    dataForColumn:null
  },
  reducers: {
    fetchStart: (state) => ({
      ...state,
      loading: true,
    }),
    fetchSuccess: (state) => ({
      ...state,
      loading: false,
    }),
    fetchError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    updateReportData:(state,action) => ({
        ...state,
        data:action.payload
    }),
    updateReportDataColumn:(state,action) => ({
        ...state,
        dataForColumn:action.payload
    })
  },
});

export const {
  fetchStart,
  fetchSuccess,
  fetchError,
  updateReportData,
  updateReportDataColumn
} = ReportSlice.actions;
export default ReportSlice.reducer;
